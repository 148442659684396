<template>
  <v-text-field
    v-model="displayValue"
    min="0"
    step="0.01"
    required
    persistent-hint
    :disabled="disabled"
    :hide-details="hideDetails"
    :prepend-icon="icon"
    :hint="hintFormatted"
    :label="label"
    :rules="mergedRules"
  ></v-text-field>
</template>

<script>
import helpers from "@/helpers.js";

export default {
  name: "DistanceTextArea",
  components: {
  },
  props: {
    value: Number,
    label: String,
    unit: String,
    icon: String,
    rules: Array,
    hint: String,
    hideDetails: Boolean,
    disabled: Boolean,
    mode: {
      type: Number,
      default: helpers.UnitType.DISTANCE,
    },
    multiplier: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      isInputActive: false,
      defaultRules: [
        v  => {
            if (!v || !v.trim()) return true;
            if (v.match(/[a-zA-Z]+/)) return 'Please enter a valid number.';
            return true;
        }
      ],
    };
  },
  async mounted() {
  },
  methods: {
    /*handleChange(e) {
      let newValue = this.parseLocaleNumber(e)
      console.log('parsed', e, 'emitting', newValue);
      this.value = newValue;
      this.$emit('input', newValue); 
    },
    */
    parseLocaleNumber(stringNumber) {
      if (stringNumber == null) {
        return null;
      }
      var thousandSeparator = ',';
      var decimalSeparator = '.';
      //try {
        // seems not to work as expected on mac
        thousandSeparator = (1111).toLocaleString(navigator.language).replace(/1/g, '');
        decimalSeparator = (1.1).toLocaleString(navigator.language).replace(/1/g, '');
      //}
      //catch { /* never mind, use default */}
      //if (stringNumber.endsWith(decimalSeparator)) {
      //  stringNumber = stringNumber.replace(decimalSeparator, '');
      //}

      console.log('Trying to parse', stringNumber, 'separator', decimalSeparator, 'culture', navigator.language, 'thousand separator', thousandSeparator);

      if (thousandSeparator) {
        return parseFloat(stringNumber
            .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
            .replace(new RegExp('\\' + decimalSeparator), '.')
        );
      }
      return parseFloat(stringNumber.replace(new RegExp('\\' + decimalSeparator), '.')
      );
    },

  },
  computed: {
    mergedRules() {
      return this.rules ? this.rules.concat(this.defaultRules) : this.defaultRules;
    },
    displayValue: {
        get: function() {
          if (this.mode == helpers.UnitType.ELEVATION) {
            //console.log('displayValue', this.value, this.unit);
            return this.$options.filters.elevation(this.value, this.unit, /* ignoreUnit: */ true);
          }
          if (this.mode == helpers.UnitType.NUMBER) {
            const multiplier = Number.isSafeInteger(this.multiplier) ? this.multiplier : 1;
            //console.log('displayValue', this.value, multiplier);
            if (this.value === 0) {
              return '0';
            }
            return this.value ? (this.value/multiplier).toLocaleString(navigator.language, {minimumFractionDigits: 0, maximumFractionDigits: 2}) : '';
          }
          //console.log('displayValue', this.value, this.unit);
          return this.$options.filters.distance(this.value, this.unit, /* ignoreUnit: */ true);
        },
        set: function(modifiedValue) {
          const multiplier = Number.isSafeInteger(this.multiplier) ? this.multiplier : 1;
          // Recalculate value after ignoring "$" and "," in user input
          let newValue = this.parseLocaleNumber(modifiedValue)
          // Ensure that it is not NaN
          if (this.mode == helpers.UnitType.NUMBER) {
            if (isNaN(newValue)) {
                newValue = undefined;
            }
            newValue *= multiplier;
            //console.log('input:', modifiedValue, 'multiplier:', multiplier, 'parsed:', newValue, 'emitting:', newValue);
            this.$emit('input', newValue)
            return;
          }
          if (isNaN(newValue)) {
              newValue = 0
          }
          var meters = 0;
          if (this.unit == 'IMPERIAL'){
            // Note: we emit the unrounded values here to ensure it don't get changed when converted back and forth to metric units
            meters =  this.mode == helpers.UnitType.ELEVATION ? newValue * (1/3.2808399) : Math.round(newValue * 1609.344);
          }
          else {
            meters = this.mode == helpers.UnitType.ELEVATION ? newValue : Math.round(newValue * 1000);
          }
          //console.log('input:', modifiedValue, 'mode', this.mode, 'multiplier:', multiplier, 'parsed:', newValue, 'emitting:', meters);
          // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
          // $emit the event so that parent component gets it
          this.$emit('input', meters);
        }
    },

    formatted() {
      if (this.mode == helpers.UnitType.NUMBER) {
        return this.value === undefined || this.value == null ? '' : `${this.value}`;
      }
      return this.mode == helpers.UnitType.ELEVATION 
        ? this.$options.filters.elevation(this.value, this.unit, /* ignoreUnit: */ true)
        : this.$options.filters.distance(this.value, this.unit, /* ignoreUnit: */ true);
    },

    hintFormatted() {
      if (this.hint) {
        return this.hint;
      }
      //console.log('distance', this.value);
      if (this.mode == helpers.UnitType.NUMBER) {
        return this.value === undefined || this.value == null ? '' : `${this.value}`;
      }
      return this.value === undefined || this.value == null ? '' : `${this.value} m`;
    },
  },
  watch: {
    mode(val) {
      console.log('switching mode to', val);
    },
  }
};
</script>
<style lang="scss">
</style>

